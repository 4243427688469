/* eslint-disable */
/*
  Load the full header and/or footer
  <div id="sxmphx-nav-header" class="sxmphx-nav-widget"></div>
  <div id="sxmphx-nav-footer" class="sxmphx-nav-widget"></div>

  Load the simple header and/or footer
  <div id="sxmphx-nav-header" class="sxmphx-nav-widget" data-simple="true"></div>
  <div id="sxmphx-nav-footer" class="sxmphx-nav-widget" data-simple="true"></div>

  Standard widget script inclusion. Nav CSS & JS files will load from www.siriusxm.com
  <script src="https://www.siriusxm.com/cms/static/global/js/minified/sxm.navwidget.min.js"></script>

  Same as above except the nav CSS & JS files will load from the domain in data-domain. For local hosting of those files.
  <script src="https://www.siriusxm.com/cms/static/global/js/minified/sxm.navwidget.min.js" data-domain="your/domain"></script>
*/
/* eslint-enable */

(function () {
  const header = document.getElementById('sxmphx-nav-header');
  const footer = document.getElementById('sxmphx-nav-footer');
  const me = document.querySelector("[src*='sxm.navwidget.min.js']");
  const domain = me ? me.getAttribute('data-domain') : 'https://www.siriusxm.com';
  const dataPrivacy = me.getAttribute('data-no-privacy') === "true" ? true : false;
  const stylesheet = document.createElement('link');
  const script = document.createElement('script');
  let total = 0;
  let completed = 0;

  stylesheet.setAttribute('rel', 'stylesheet');
  stylesheet.setAttribute('href', `${domain }/cms/static/global/css/minified/sxm.nav.min.css`);
  script.setAttribute('src', `${domain }/cms/static/global/js/minified/sxm.nav.min.js`);

  document.querySelector('head').appendChild(stylesheet);

  function isNavComplete() {
    if (completed >= total) {
      document.querySelector('body').appendChild(script);

      const snapshot = document.querySelector('sxm-ui-nav-account-presence-icons');

      if (snapshot) {
        const scripts = [...snapshot.parentNode.querySelectorAll('script')];
        scripts.forEach((scriptEl) => {
          const scriptTag = document.createElement('script');
          scriptTag.src = scriptEl.src;
          scriptEl.parentNode.removeChild(scriptEl);
          document.querySelector('body').appendChild(scriptTag);
        });
      }
    }
  }

  if (header) {
    total += 1;
    let params = '';
    const headerRequest = new XMLHttpRequest();
    const headerType = header.getAttribute('data-simple') === 'true' ? 'simplenavigation' : 'fullnavigation';

    headerRequest.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        header.innerHTML = headerRequest.responseText;
        completed += 1;
        isNavComplete();
      }
    };

    if (headerType === 'simplenavigation') {
      params += '&style=white';
    }

    headerRequest.open('GET', `${domain}/phx/services/nav/${headerType}?locale=en_US&segments=sxm&d=Desktop${params}`, true);
    headerRequest.send();
  }

  if (footer) {
    total += 1;
    const footerRequest = new XMLHttpRequest();
    const footerType = footer.getAttribute('data-simple') === 'true' ? 'simplefooter' : 'fullfooter';

    footerRequest.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        footer.innerHTML = footerRequest.responseText;
        if (dataPrivacy) {
          const cookieLink = footer.querySelector('.ot-sdk-show-settings');
          cookieLink.parentNode.parentNode.removeChild(cookieLink.parentNode);
        }
        completed += 1;
        isNavComplete();
      }
    };


    footerRequest.open('GET', `${domain}/phx/services/nav/${footerType}?locale=en_US&segments=sxm&d=Desktop`, true);
    footerRequest.send();
  }
}());
